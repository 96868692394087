export default {
  //textarea
  textarea: {
    base: "block w-full text-sm text-gray-300 rounded-md focus:outline-none p-3 ",
    active:
      "focus:border-red-400  border-gray-600 bg-gray-700 focus:ring-gray-300 focus:ring focus:ring-red-300",
    disabled: "cursor-not-allowed opacity-50 bg-gray-800",
    valid:
      "border-green-600 bg-gray-700 focus:border-green-400 focus:ring focus:ring-green-200",
    invalid:
      "border-red-600 bg-gray-700 focus:border-red-400 focus:ring focus:ring-red-200",
  },
  // Input
  input: {
    base: "p-3 block w-full text-sm focus:outline-none text-gray-300 leading-5 rounded-md",
    active:
      " border-gray-600 focus:ring focus:border-red-600 focus:ring-red-300 bg-gray-700",
    disabled: "cursor-not-allowed opacity-50 bg-gray-800",
    valid:
      "border-green-600 bg-gray-700 focus:border-green-400  focus:ring focus:ring-green-200 ",
    invalid:
      "border-red-600 bg-gray-700 focus:border-red-400 focus:ring focus:ring-red-200",
    radio:
      "text-red-600 form-radio focus:border-red-400 focus:outline-none focus:ring focus:ring-red-300 focus:ring-offset-0 ",
    checkbox:
      "text-red-600 form-checkbox focus:border-red-400 focus:outline-none focus:ring focus:ring-red-300 focus:ring-offset-0 rounded ",
  },
  // Pagination
  pagination: {
    base: "flex flex-col justify-between text-xs sm:flex-row text-gray-600 ",
  },
  // Select
  select: {
    base: "block p-3 w-full text-sm dark:text-gray-300 focus:outline-none rounded-md",
    active:
      "focus:border-red-400 border-gray-300  focus:ring focus:ring-red-300 dark:focus:ring-gray-300 dark:focus:border-gray-600",
    select: "leading-5",
    disabled: "cursor-not-allowed opacity-50 bg-gray-300 dark:bg-gray-800",
    valid:
      "border-green-600 dark:bg-gray-700 focus:border-green-400 dark:focus:border-green-400 focus:ring focus:ring-green-200 dark:focus:ring-green-200",
    invalid:
      "border-red-600 dark:bg-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:ring focus:ring-red-200 dark:focus:ring-red-200",
  },
};
